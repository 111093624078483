var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page1" }, [
    _c(
      "div",
      { staticClass: "breadcrumb" },
      [
        _c(
          "el-button",
          {
            staticClass: "right",
            staticStyle: { width: "88px", height: "36px" },
            attrs: { type: "primary" },
            on: {
              click: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          },
          [_vm._v("返回")]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "baseInfo" },
        [
          _c("TitleModule", { attrs: { title: "基本信息" } }),
          _c("info-table", {
            attrs: {
              dataValue: _vm.detail,
              nameStyle: { width: "150px" },
              valueStyle: {
                width: "calc(100% - 150px)",
              },
              num: 3,
              tableData: _vm.tableData,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "auditRecord" },
        [
          _c("TitleModule", { attrs: { title: "状态信息" } }),
          _c("info-table", {
            attrs: {
              nameStyle: { width: "150px" },
              valueStyle: {
                width: "calc(100% - 150px)",
              },
              num: 3,
              dataValue: _vm.detail,
              tableData: _vm.auditRecordTable,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "auditHandle" },
        [
          _c("TitleModule", { attrs: { title: "原因" } }),
          _c("info-table", {
            attrs: {
              dataValue: _vm.detail,
              nameStyle: { width: "150px" },
              valueStyle: { width: "calc(100% - 150px)" },
              num: 3,
              tableData: _vm.auditHandleTable,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }